import React, { useState } from 'react';
import { Dropdown, Menu, Tooltip, DatePicker } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import locale from "antd/lib/date-picker/locale/fr_FR";
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

dayjs.locale('fr');
const { RangePicker } = DatePicker;

// Style personnalisé pour les boutons avec biseau
const CustomDownloadButton = ({ children, onClick, title, disabled = false }) => {
  // Styles de base pour le bouton principal
  const buttonStyle = {
    backgroundColor: '#ee463b',
    fontFamily: "Quicksand",
    textTransform: "uppercase",
    color: 'white',
    border: 'none',
    padding: '8px 16px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'normal',
    position: 'relative',
    cursor: 'pointer',
    outline: 'none',
    minWidth: 170
  };

  // Style du conteneur SVG (pour le biseau)
  const svgContainerStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '20px', // Augmenté pour un meilleur angle
    overflow: 'hidden',
  };

  // Style du SVG
  const svgStyle = {
    fill: buttonStyle.backgroundColor, // Utilise la même couleur que le bouton
    height: '100%',
    width: '100%',
  };

  return (
    <Tooltip title={title}>
      <button
        style={buttonStyle}
        type="button"
        disabled={disabled}
        onClick={onClick}
      >
        {children}
        <span className="svg-container" style={svgContainerStyle}>
          <svg
            version="1.1"
            height="100%"
            width="100%"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            style={svgStyle}
          >
            <path d="M 15,100 H 0 V 0 H 100 Z"></path>
          </svg>
        </span>
      </button>
    </Tooltip>
  );
};

// Composant de bouton dropdown personnalisé
const CustomDropdownButton = ({ children, menu, onClick }) => {
  // Styles pour le conteneur du bouton dropdown
  const containerStyle = {
    display: 'inline-flex',
    position: 'relative',
  };

  // Style pour le bouton de menu (avec l'icône)
  const menuButtonStyle = {
    backgroundColor: '#ee463b', // Couleur rouge comme le bouton EXPORT CSV
    color: 'white',
    border: 'none',
    borderLeft: '1px solid rgba(255, 255, 255, 0.5)', // Séparateur
    padding: '8px 10px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    width: '40px', // Augmenté pour que l'icône ne soit pas rognée
  };

  // Style du conteneur SVG pour le bouton de menu
  const menuSvgContainerStyle = {
    position: 'absolute',
    top: 0,
    left: 38,
    height: '100%',
    width: 28, // Augmenté pour un meilleur angle
    overflow: 'hidden',
  };

  // Style du SVG pour le bouton de menu
  const menuSvgStyle = {
    fill: menuButtonStyle.backgroundColor,
    height: '100%',
    width: '100%',
  };

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      open={visible}
      onOpenChange={handleVisibleChange}
    >
      <div style={containerStyle}>
        <CustomDownloadButton onClick={onClick} title="Télécharger toutes les données">
          {children}
        </CustomDownloadButton>
        <button style={menuButtonStyle} onClick={(e) => e.preventDefault()}>
          <DownloadOutlined />
          <span style={menuSvgContainerStyle}>
            <svg
              version="1.1"
              height="100%"
              width="100%"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              style={menuSvgStyle}
            >
              <path d="M 15,100 H 0 V 0 H 100 Z"></path>
            </svg>
          </span>
        </button>
      </div>
    </Dropdown>
  );
};

const DownloadDropdown = ({ onDownload, exportComponent }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  const handleMenuClick = (e) => {
    if (e.key === 'all') {
      // Call the endpoint without date parameters
      onDownload();
      setShowDatePicker(false);
    } else if (e.key === 'period') {
      // Show date picker
      setShowDatePicker(true);
    }
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      // Convert to unix timestamps (seconds)
      const startDate = Math.floor(dates[0].valueOf() / 1000);
      const endDate = Math.floor(dates[1].valueOf() / 1000);
      
      setDateRange({ startDate, endDate });
      
      // Call endpoint with date parameters
      onDownload({ startDate, endDate });
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="all">Tous</Menu.Item>
      <Menu.Item key="period">Période</Menu.Item>
    </Menu>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Custom Export CSV button */}
        <div style={{ width: 300 }}>{exportComponent}</div>
        
        {/* Custom Download dropdown button */}
        <CustomDropdownButton 
          menu={menu} 
          onClick={() => onDownload()}
        >
          Bénéficiaires
        </CustomDropdownButton>
      </div>
      
      {showDatePicker && (
        <div style={{ marginTop: '8px' }}>
          <RangePicker 
            picker="week" 
            onChange={handleDateChange}
            allowClear={true}
            format="YYYY [Semaine] w"
            locale={locale}
          />
        </div>
      )}
    </div>
  );
};

export default DownloadDropdown;

// Example of how to use this component
/*
import React from 'react';
import DownloadDropdown from './DownloadDropdown';

const MyComponent = () => {
  const handleDownload = (dateRange) => {
    if (dateRange) {
      // Appel avec période spécifiée
      console.log(`Téléchargement pour la période du ${dateRange.startDate} au ${dateRange.endDate}`);
      // Votre appel API avec les paramètres de date
    } else {
      // Appel sans période (tous)
      console.log('Téléchargement de toutes les données');
      // Votre appel API sans paramètres de date
    }
  };

  // Votre bouton Export CSV personnalisé
  const exportButton = (
    <button 
      style={{ 
        backgroundColor: '#f44336', 
        color: 'white', 
        border: 'none', 
        padding: '8px 16px', 
        marginRight: '10px',
        clipPath: 'polygon(0 0, 100% 0, 95% 100%, 0 100%)', // Pour créer l'effet de coupe
        fontWeight: 'bold'
      }}
    >
      EXPORT CSV
    </button>
  );

  return (
    <div>
      <DownloadDropdown onDownload={handleDownload} exportComponent={exportButton} />
    </div>
  );
};
*/