import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Divider, Space, Upload, Spin, Typography } from "antd";
import {
  UploadOutlined,
  DownloadOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import { AuthContext } from "../../context/AuthProviderWrapper";
import { NotificationContext } from "../../context/NotificationWrapper";

const { Title, Paragraph, Text } = Typography;

function BeneficiaryPage() {
  const { activeCompany } = useContext(AuthContext);
  const { openNotification } = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [hasFile, setHasFile] = useState(false);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (activeCompany?.membershipCode) {
      checkExistingFile();
    }
  }, [activeCompany]);

  const checkExistingFile = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BACKEND_URL}/api/beneficiary-file/download/${activeCompany.membershipCode}`,
        {
          withCredentials: true,
          responseType: "blob",
        }
      );
      
      // Le fichier existe si on arrive ici
      setHasFile(true);
      
      // Tenter d'extraire le nom du fichier depuis les headers
      const contentDisposition = response.headers["content-disposition"];
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename=([^;]*)/);
        if (filenameMatch && filenameMatch[1]) {
          setFileName(filenameMatch[1]);
        }
      } else {
        setFileName(`ADH${activeCompany?.membershipCode}-beneficiaires.xlsx`);
      }
    } catch (error) {
      // 404 = pas de fichier, c'est normal
      if (error.response && error.response.status !== 404) {
        openNotification(
          "Erreur",
          "Impossible de vérifier si un fichier existe déjà",
          "error"
        );
      }
      setHasFile(false);
      setFileName("");
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async (options) => {
    const { file, onSuccess, onError } = options;
    
    if (!activeCompany?.membershipCode) {
      openNotification(
        "Erreur",
        "Veuillez sélectionner une entreprise avant d'uploader un fichier",
        "error"
      );
      onError("Aucune entreprise sélectionnée");
      return;
    }
    
    const formData = new FormData();
    formData.append("file", file);
    formData.append("membershipCode", activeCompany.membershipCode);
    
    try {
      setLoading(true);
      setUploadErrors([]);
      const response = await axios.post(
        `${BACKEND_URL}/api/beneficiary-file/upload`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      onSuccess("OK");
      openNotification(
        "Succès",
        "Le fichier de bénéficiaires a été importé avec succès",
        "success"
      );
      checkExistingFile();
    } catch (error) {
      onError("Erreur d'upload");
      
      if (error.response && error.response.data && error.response.data.data) {
        // Format attendu des erreurs: tableau ou objet avec des erreurs par ligne
        const errorData = error.response.data.data;
        if (Array.isArray(errorData)) {
          setUploadErrors(errorData);
        } else if (typeof errorData === 'object') {
          const formattedErrors = [];
          Object.keys(errorData).forEach(key => {
            if (Array.isArray(errorData[key])) {
              formattedErrors.push(`${key}: ${errorData[key].join(', ')}`);
            } else {
              formattedErrors.push(`${key}: ${errorData[key]}`);
            }
          });
          setUploadErrors(formattedErrors);
        } else {
          setUploadErrors([JSON.stringify(errorData)]);
        }
      } else {
        openNotification(
          "Erreur",
          "Une erreur est survenue lors de l'import du fichier",
          "error"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadTemplate = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BACKEND_URL}/api/beneficiary-file/export-template`,
        {
          withCredentials: true,
          responseType: "blob",
        }
      );
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "modele_beneficiaires.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      openNotification(
        "Erreur",
        "Impossible de télécharger le modèle de fichier",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadFile = async () => {
    if (!activeCompany?.membershipCode) {
      openNotification(
        "Erreur",
        "Veuillez sélectionner une entreprise avant de télécharger un fichier",
        "error"
      );
      return;
    }
    
    try {
      setLoading(true);
      const response = await axios.get(
        `${BACKEND_URL}/api/beneficiary-file/download/${activeCompany.membershipCode}`,
        {
          withCredentials: true,
          responseType: "blob",
        }
      );
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      
      // Utiliser le nom du fichier s'il est disponible, sinon un nom par défaut
      const downloadName = fileName || "beneficiaires.xlsx";
      link.setAttribute("download", downloadName);
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      openNotification(
        "Erreur",
        "Impossible de télécharger le fichier",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFile = async () => {
    if (!activeCompany?.membershipCode) {
      openNotification(
        "Erreur",
        "Veuillez sélectionner une entreprise avant de supprimer un fichier",
        "error"
      );
      return;
    }
    
    try {
      setLoading(true);
      await axios.post(
        `${BACKEND_URL}/api/beneficiary-file/delete`,
        { membershipCode: activeCompany.membershipCode },
        {
          withCredentials: true,
        }
      );
      
      openNotification(
        "Succès",
        "Le fichier a été supprimé avec succès",
        "success"
      );
      setHasFile(false);
      setFileName("");
    } catch (error) {
      openNotification(
        "Erreur",
        "Impossible de supprimer le fichier",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="block-right">
      <Spin spinning={loading} tip="Chargement...">
        <Card className="beneficiary-card">
          <Title level={2}>Gestion des bénéficiaires</Title>
          
          <Alert
            type="info"
            icon={<InfoCircleOutlined />}
            showIcon
            message="Guide de remplissage du fichier Excel des bénéficiaires"
            description={
              <div>
                <Paragraph>
                Le fichier doit être au format Excel (.xlsx ou .xls) avec les colonnes dans cet ordre : <b>Titre, Nom, Prénom, Date de naissance, Catégorie socioprofessionnelle (CSP)</b>, Siret, Adresse, Code postal, Ville.
                </Paragraph>
                <Paragraph>
                Les champs obligatoires sont : <b>Titre</b> (uniquement "M" ou "MME"), <b>Nom, Prénom, Date de naissance</b> (format JJ/MM/AAAA) et <b>CSP</b> (uniquement "OUVRIER", "CADRE", "ETAM"). 
                </Paragraph>
                <Paragraph>
                Vous devez, si vous avez plusieurs établissements, indiquer le numéro SIRET de chacun de vos établissements : colonne <b>SIRET</b> (Système d'identification du répertoire des établissements).
                </Paragraph>
                <Paragraph>
                Si vous souhaitez l'envoi direct par voie postale des cartes Bénéficiaire APAS-BTP, alors vous devez ajouter les champs suivants dans cet ordre : <b>Adresse, Code postal</b> et <b>Ville</b>
                </Paragraph>
                <Paragraph>
                N'ajoutez pas de colonnes supplémentaires et ne laissez pas de lignes vides entre les bénéficiaires.
                </Paragraph>
                <Paragraph>
                Importer un fichier remplacera l'import précédent.
                Lors de l'import, tous les champs sont passés en majuscules.
                Un modèle est disponible en cliquant sur "Télécharger le modèle de fichier".
                </Paragraph>
              </div>
            }
            style={{ marginBottom: 24 }}
          />

          {uploadErrors.length > 0 && (
            <Alert
              type="error"
              message="Erreurs lors de l'import du fichier"
              description={
                <ul>
                  {uploadErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              }
              showIcon
              closable
              style={{ marginBottom: 24 }}
            />
          )}

          <Space direction="vertical" style={{ width: '100%' }}>
            <Divider orientation="left">Actions disponibles</Divider>
            
            <Space size="middle">
              <Upload
                name="file"
                customRequest={handleUpload}
                showUploadList={false}
                accept=".xlsx,.xls"
              >
                <Button icon={<UploadOutlined />} type="primary">
                  Importer un fichier de bénéficiaires
                </Button>
              </Upload>
              
              <Button 
                icon={<DownloadOutlined />} 
                onClick={handleDownloadTemplate}
              >
                Télécharger le modèle de fichier
              </Button>
            </Space>
            
            {hasFile && (
              <div style={{ marginTop: 16 }}>
                <Divider orientation="left">Fichier actuel</Divider>
                <Space size="middle">
                  <Text strong>{fileName}</Text>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={handleDownloadFile}
                  >
                    Télécharger
                  </Button>
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={handleDeleteFile}
                  >
                    Supprimer
                  </Button>
                </Space>
              </div>
            )}
          </Space>
        </Card>
      </Spin>
    </div>
  );
}

export default BeneficiaryPage;