import React, { useContext } from "react";
import { Button, ButtonLink } from "../../misc/button/Button";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import { NotificationContext } from "../../context/NotificationWrapper";

function DeclarationCertificatePayment() {
  const { state } = useLocation();
  const CreateDeclarationd = state?.message;
  const { openNotification } = useContext(NotificationContext);

    const handleDownloadPdf = (element) => {
      axios
        .get(`${BACKEND_URL}/api/declaration/bill-pdf/${element.id}`, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf, application/zip", // Accepter les types PDF et ZIP
          },
          responseType: "blob",
        })
        .then((response) => {
          // Extraire le nom du fichier de l'en-tête Content-Disposition
          const contentDisposition = response.headers['content-disposition'];
          let filename = 'facture.pdf'; // Nom par défaut
          
          if (contentDisposition) {
            // Amélioration de l'extraction du nom de fichier avec support pour les guillemets
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }
          
          // Déterminer l'extension de fichier correcte à partir du type de contenu
          const contentType = response.headers['content-type'];
          if (contentType && contentType.includes('application/zip')) {
            // Assurer que l'extension est .zip si le type de contenu est zip
            if (!filename.toLowerCase().endsWith('.zip')) {
              filename = filename.replace(/\.[^/.]+$/, "") + '.zip';
            }
          } else if (contentType && contentType.includes('application/pdf')) {
            // Assurer que l'extension est .pdf si le type de contenu est pdf
            if (!filename.toLowerCase().endsWith('.pdf')) {
              filename = filename.replace(/\.[^/.]+$/, "") + '.pdf';
            }
          }
          
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((error) => {
          console.error("error", error);
          // Afficher une notification d'erreur à l'utilisateur
          openNotification("Erreur de téléchargement", "Impossible de télécharger le fichier. Veuillez réessayer plus tard.", "error");
        });
    };

  return (
    <div className="block-right">
      <div className="info-declaration">
        <h4 style={{ textTransform: "uppercase" }}>Certificat de dépôt </h4>
        <p
          style={{ fontStyle: "italic", fontWeight: "900", marginTop: "10px" }}
        >
          Votre déclaration est maintenant terminée. Cette facture est délivrée
          par l'APAS-BTP et atteste du dépôt de votre déclaration. Celle-ci sera
          consultable en utilisant le service « Consulter mon compte adhérent »
          accessible à partir de votre espace adhérent.
        </p>
        <Button
          onClick={() => handleDownloadPdf(CreateDeclarationd)}
          children="Télécharger la facture"
        />
      </div>
      <div className="info-payment">
        <h4 style={{ textTransform: "uppercase" }}>
          Accès au service de paiement{" "}
        </h4>
        <p
          style={{ fontStyle: "italic", fontWeight: "900", marginTop: "10px" }}
        >
          Vous pouvez payer en cliquant sur l'un des boutons ci-dessous.
        </p>

        <div className="button-container">
          <ButtonLink
            to="/declaration-cb"
            state={{ message: CreateDeclarationd }}
            children="Payer par CB"
          />
          <ButtonLink
            to="/declaration-payment"
            state={{ message: CreateDeclarationd }}
            children="Payer par virement"
          />
        </div>
      </div>
    </div>
  );
}

export default DeclarationCertificatePayment;
