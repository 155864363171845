import { Table } from "antd";
import React from "react";

function TableDeclarations(props) {
  const { category, handleTableChange, data, filters, totalItems, isLoading } =
    props;

  const columns = [
    {
      title: "Code Adhérent",
      dataIndex: "membershipCode",
      key: "membershipCode",
      align: "center",
      filteredValue: undefined,
    },
    {
      title: "Raison Sociale",
      dataIndex: "companyName",
      key: "companyName",
      align: "center",
      filteredValue: undefined,
    },
    {
      title: "Catégorie Adhérent",
      dataIndex: "membershipCategory",
      key: "membershipCategory",
      align: "center",
      filters: [
        { text: "4 : Etam Cadres & Ouvriers", value: "4" },
        { text: "5 : Adhérent Volontaire", value: "5" },
        { text: "6 : Adhérent CAPEB / - de 10", value: "6" },
      ],
      filteredValue: category || null,
    },
    {
      title: "Date saisie",
      dataIndex: "formatedInputedDate",
      key: "formatedInputedDate",
      align: "center",
      filteredValue: undefined,
    },
    {
      title: "Date de déclaration",
      dataIndex: "formatedValidatedDate",
      key: "formatedCreationDate",
      align: "center",
      filteredValue: undefined,
    },
    {
      title: "Montant de la cotisation",
      dataIndex: "feeAmount",
      key: "feeAmount",
      align: "right",
      filteredValue: undefined,
    },
    {
      title: "Effectifs déclarés",
      dataIndex: "workForce",
      key: "workForce",
      align: "right",
      filteredValue: undefined,
    },
    {
      title: "Statut",
      dataIndex: "status",
      key: "status",
      align: "center",
      filters: [
        { text: "Actif", value: "active" },
        { text: "Demande en attente", value: "pending_modification" },
        { text: "Demande approuvée", value: "modification_approved" },
        { text: "Annulé", value: "cancelled" }
      ],
      filteredValue: props.status || null,
      // defaultFilteredValue: ["active", "pending_modification", "modification_approved"],
    },
    {
      title: "Méthode paiement",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      align: "center",
      filteredValue: undefined,
    },
    {
      title: "Réglé",
      dataIndex: "payed",
      key: "payes",
      align: "center",
      filteredValue: undefined,
    },
    {
      title: "Actions",
      key: "edit",
      dataIndex: "edit",
      filteredValue: undefined,
    },
    {
      title: "Actions Modification",
      key: "modificationActions",
      dataIndex: "modificationActions",
      align: "center",
      filteredValue: undefined,
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      style={{ flexGrow: 1 }}
      scroll={{ x: "100%" }}
      rowKey={(row) => row.id}
      pagination={{
        current: filters.page,
        pageSize: 50,
        total: totalItems,
      }}
      onChange={handleTableChange}
      loading={isLoading}
    />
  );
}

export default TableDeclarations;
