import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthProviderWrapper";
import { useMediaQuery } from "react-responsive";

function MenuNavigation() {
  const { user, isAdmin } = useContext(AuthContext);
  const location = useLocation();
  const isSmallScreen = useMediaQuery({ maxWidth: 990 });

  return (
    <div className="block-left">
      {(user?.companies?.length >= 1 || isAdmin || !isSmallScreen) && (
        <ul>
          {isAdmin && (
            <li>
              <Link
                to="/admin"
                className={
                  location.pathname === "/infoIdentifier" ? "active-link" : ""
                }
              >
                Dashboard
              </Link>
            </li>
          )}
          {!isAdmin && (
            <>
              <li>
                <Link
                  to="/infoIdentifier"
                  className={
                    location.pathname === "/infoIdentifier" ? "active-link" : ""
                  }
                >
                  Informations adhérent
                </Link>
              </li>
              <li>
                <Link
                  to="/declaration"
                  className={
                    location.pathname === "/declaration" ? "active-link" : ""
                  }
                >
                  Ajouter Déclaration
                </Link>
              </li>
            </>
          )}
          <li>
            <Link
              to="/tableDeclaration"
              className={
                location.pathname === "/tableDeclaration" ? "active-link" : ""
              }
            >
              {isAdmin ? "Déclarations" : "Mes Déclarations"}
            </Link>
          </li>
          {!isAdmin && (
            <li>
              <Link
                to="/beneficiaries"
                className={
                  location.pathname === "/beneficiaries" ? "active-link" : ""
                }
              >
                Mes Bénéficiaires
              </Link>
            </li>
          )}
          {isAdmin && (
            <>
              <li>
                <Link
                  to="/users"
                  className={
                    location.pathname === "/users" ? "active-link" : ""
                  }
                >
                  Adhérents
                </Link>
              </li>
              <li>
                <Link
                  to="/import"
                  className={
                    location.pathname === "/import" ? "active-link" : ""
                  }
                >
                  Import CSV
                </Link>
              </li>
              <li>
                <Link
                  to="/payment-status-change"
                  className={
                    location.pathname === "/payment-status-change"
                      ? "active-link"
                      : ""
                  }
                >
                  Statut Paiements
                </Link>
              </li>
            </>
          )}
        </ul>
      )}
    </div>
  );
}

export default MenuNavigation;