import React, { useState, useEffect } from "react";
import { Modal, Form, Input } from "antd";
import { Button } from "../../misc/button/Button";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const CancelDeclarationModal = ({ visible, onCancel, onConfirm, record }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    if (visible) {
      setIsLoading(false);
    } else {
      form.resetFields();
      setIsLoading(false);
    }
  }, [visible, form]);
  
  const handleOk = () => {
    setIsLoading(true);
    form.validateFields()
      .then((values) => {
        onConfirm(record.id, values.reason)

      })
      .catch((info) => {
        console.error("Validation failed:", info);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  // Fonction personnalisée pour fermer et réinitialiser
  const handleCancel = () => {
    onCancel();
    form.resetFields();
    setIsLoading(false);
  };
  
  return (
    <Modal
      title={
        <div>
          <ExclamationCircleOutlined style={{ color: "#ff4d4f", marginRight: 8 }} />
          Annuler la déclaration
        </div>
      }
      open={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} style={{marginRight:50}}>
          Annuler
        </Button>,
        <Button
          key="submit"
          type="button"
          onClick={handleOk}
          danger
          children={"Confirmer l'annulation"}
          style={{marginRight:20}}
        />,
      ]}
    >
      <p>
        Êtes-vous sûr de vouloir annuler la déclaration pour{" "}
        <strong>{record?.formatedInputedDate}</strong> d'un montant de{" "}
        <strong>{record?.feeAmountLocale}</strong> ?
      </p>
      <p>Cette action est irréversible.</p>
     
      <Form form={form} layout="vertical">
        <Form.Item
          name="reason"
          label="Raison de l'annulation"
          rules={[{ required: true, message: "Veuillez indiquer la raison de l'annulation" }]}
        >
          <Input.TextArea rows={4} placeholder="Indiquez la raison de l'annulation..." />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CancelDeclarationModal;