import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Spin } from 'antd';
import { Button } from "../../misc/button/Button";

const { TextArea } = Input;

const RequestModificationModal = ({ visible, onCancel, onConfirm, record }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      setIsSubmitting(false);
      form.resetFields();
    }
  }, [visible, form]);

  const handleSubmit = () => {
    form.validateFields()
      .then(values => {
        setIsSubmitting(true);
        onConfirm(record.id, values.reason);
      })
      .catch(error => {
        console.log('Validation failed:', error);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsSubmitting(false);
    onCancel();
  };

  return (
    <Modal
      title="Demande de modification de déclaration"
      open={visible}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={null}
    >
      <Spin spinning={isSubmitting} tip="Envoi en cours...">
        <Form
          form={form}
          layout="vertical"
          name="modificationRequestForm"
        >
          <Form.Item
            name="reason"
            label="Motif de la demande de modification"
            rules={[
              {
                required: true,
                message: 'Veuillez indiquer le motif de votre demande de modification',
              },
            ]}
          >
            <TextArea
              placeholder="Décrivez pourquoi vous souhaitez modifier cette déclaration"
              rows={4}
              style={{ resize: 'none' }}
            />
          </Form.Item>

          <div style={{ 
            display: 'flex', 
            justifyContent: 'flex-end',
            gap: '20px',
            marginTop: '20px'
          }}>
            <Button
              onClick={handleCancel}
              children="Annuler"
              style={{marginRight:20}}
            />
            <Button
              onClick={handleSubmit}
              children="Confirmer la demande"
              isDisabled={isSubmitting}
              style={{marginRight:20}}
            />
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default RequestModificationModal;