/**
 * Utilitaire pour la gestion des saisies monétaires
 */

/**
 * Fonction utilitaire pour traiter et valider les entrées monétaires
 * @param {Object|Event} e - L'événement ou la valeur
 * @returns {Object|string} - L'événement modifié ou la valeur formatée
 */
export const handleMoneyChange = (e) => {
    // Déterminer si on a un événement ou une valeur directe
    let value;
    
    if (e && e.target && e.target.value !== undefined) {
      // C'est un événement DOM standard
      value = e.target.value;
    } else if (e && typeof e === 'object' && e.value !== undefined) {
      // Format spécifique à antd parfois utilisé
      value = e.value;
    } else if (typeof e === 'string') {
      // C'est directement une chaîne de caractères
      value = e;
    } else {
      console.error("Format d'entrée non reconnu pour handleMoneyChange:", e);
      return e; // Retourner l'entrée inchangée
    }
  
    // Appliquer les transformations sur la valeur
    // we replace all dots by commas
    value = value.replace(/\./g, ",");
    // next, we replace the first comma by a dot
    value = value.replace(/,/, ".");
    // removal af all others commas
    value = value.replace(/,/g, "");
    // finally we get rid of everything that is nor a number nor a dot
    value = value.replace(/[^0-9.]/g, "");
  
    // 2 decimals max after comma
    if (value.includes(".")) {
      if (value.split(".")[1]?.length >= 2) {
        var num = parseFloat(value);
        var cleanNum = num.toFixed(2);
        value = cleanNum.toString();
      }
    }
  
    // Retourner la valeur ou mettre à jour l'événement selon le cas
    if (e && e.target && e.target.value !== undefined) {
      e.target.value = value;
      return e;
    } else {
      return value;
    }
  };