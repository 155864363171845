import React, { useEffect, useState } from "react";
import { Button, ButtonLink } from "../../../misc/button/Button";
import SpinLoading from "../../../misc/loading/SpinLoading";
import { Form } from "antd";

export const TitleEditDeclarationModal = (
  props
  ) => {
  const [headerTitle, setHeaderTitle] = useState("Déclaration à payer");
  useEffect(() => {
    let title;
    if (props.record?.status === 'modification_approved') {
      title = "Modification de déclaration";
    } else if (props.record?.status === 'pending_modification') {
      title = "Déclaration";
    } else if (props.record?.payed === "yes") {
      title = "Déclaration payée";
    } else if (props.record?.payed === "ongoing") {
      title = "Déclaration en cours de traitement";
    } else {
      title = "Déclaration à payer";
    }
    setHeaderTitle(title);
    console.log('TitleEditDeclarationModal - record:', props.record);
  }, [props.record])

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
    >
      <div>{headerTitle}</div>
      <div style={{ fontWeight: "normal", fontSize: 17, marginRight: 50 }}>
        {props.record?.indice > 0 && `Indice de facture : ${props.record?.indice}`}
      </div>
    </div>
  );
};

export const FooterEditDeclarationModal = ({
  mode,
  isLoading,
  onCloseModal,
  onSave,
  record,
  isAdmin,
}) => {
  const editFooter = (
    <div className="button-container-modal-edit">
      <div>
        <Button text="Retour" onClick={onCloseModal}>
          Retour
        </Button>
      </div>
      <Form.Item>
        <Button
          text="Modifier"
          type="submit"
          onClick={onSave}
          children={isLoading ? <SpinLoading /> : "Modifier"}
        />
      </Form.Item>
    </div>
  );

  const viewFooter = (
    <div className="button-container-modal-view">
      <Button text="Retour" onClick={onCloseModal}>
        Retour
      </Button>
      {record?.payed === "no" && record?.status === 'active' && !isAdmin && (
        <>
          <ButtonLink
            to="/declaration-cb"
            state={{ message: record }}
            children="Payer par CB"
          />
          <ButtonLink
            to="/declaration-payment"
            state={{ message: record }}
            children=" Payer par virement"
          />
        </>
      )}
    </div>
  );

  return mode === "edit" ? editFooter : viewFooter;
};
