import React, { useState } from 'react';
import { Modal, Typography, Spin } from 'antd';
import { Button } from "../../misc/button/Button";

const { Text, Paragraph, Title } = Typography;

const ModificationReviewModal = ({ visible, onCancel, onApprove, onReject, record, reason }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleApprove = async () => {
    setIsSubmitting(true);
    try {
      await onApprove(record.id);
      onCancel(); // Ferme le modal après succès
    } catch (error) {
      console.error("Erreur lors de l'approbation:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReject = async () => {
    setIsSubmitting(true);
    try {
      await onReject(record.id);
      onCancel(); // Ferme le modal après succès
    } catch (error) {
      console.error("Erreur lors du rejet:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title="Demande de modification de déclaration"
      open={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      footer={null}
    >
      <Spin spinning={isSubmitting} tip="Traitement en cours...">
        <div style={{ marginBottom: '20px' }}>
          <Title level={5}>Détails de la demande</Title>
          
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <Text strong style={{ width: '150px' }}>Code adhérent :</Text>
            <Text>{record?.membershipCode}</Text>
          </div>

          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <Text strong style={{ width: '150px' }}>Entreprise :</Text>
            <Text>{record?.companyName}</Text>
          </div>

          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <Text strong style={{ width: '150px' }}>Montant :</Text>
            <Text>{record?.feeAmount}</Text>
          </div>

          <div style={{ display: 'flex', marginBottom: '20px' }}>
            <Text strong style={{ width: '150px' }}>Date de déclaration :</Text>
            <Text>{record?.formatedInputedDate}</Text>
          </div>

          <Title level={5}>Motif de la demande</Title>
          <Paragraph 
            style={{ 
              padding: '10px', 
              background: '#f5f5f5', 
              borderRadius: '4px',
              marginBottom: '20px',
              minHeight: '80px'
            }}
          >
            {reason || "Aucun motif fourni"}
          </Paragraph>
        </div>

        <div style={{ 
          display: 'flex', 
          justifyContent: 'flex-end',
          gap: '20px'
        }}>
          <Button
            onClick={onCancel}
            children="Annuler"
            style={{marginRight:20}}
          />
          <Button
            onClick={handleReject}
            children="Rejeter"
            isDisabled={isSubmitting}
            style={{marginRight:20}}
          />
          <Button
            onClick={handleApprove}
            children="Approuver"
            isDisabled={isSubmitting}
            style={{marginRight:20}}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default ModificationReviewModal;