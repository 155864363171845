import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import Qs from "qs";
import SearchAutocomplete from "../../misc/searchAutocomplete/SearchAutocomplete";
import { EditOutlined, ClearOutlined, CheckOutlined, FileExcelOutlined } from "@ant-design/icons";
import { NotificationContext } from "../../context/NotificationWrapper";
import { Button } from "../../misc/button/Button";
import TableDeclarationsUserList from "./TableDeclarationsUserList";
import DetacheGroup from "../multiAccount/DetacheGroup";
import EditUserModal from "../userDetail-CRUD/EditUserModal";
import { useLocation } from "react-router-dom";
import ForceValidateEmailModal from "./ForceValidateEmailModal";
import DownloadDropdown from "./DownloadDropdown";

export default function UserListPage() {
  const [data, setData] = useState(null);
  const [totalItems, setTotalItems] = useState(1);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [forceValidateEmailModalVisible, setForceValidateEmailModalVisible] =
    useState(false);
  const [editedRecord, setEditedRecord] = useState(null);
  const [searchValue, setSearchValue] = useState(""); // string
  const [category, setCategory] = useState([]);
  const [laborUnion, setLaborUnion] = useState([]);
  const [laborUnionOption, setLaborUnionOption] = useState([]);
  const [workForce, setWorkForce] = useState();
  const [buildingConventionOutside77, setBuildingConventionOutside77] =
    useState([]);
  const [userIsAdmin, setUserIsAdmin] = useState(["false"]);
  const [isVerified, setIsVerified] = useState(["true"]);
  const [isLoading, setIsLoading] = useState(false);
  const { openNotification } = useContext(NotificationContext);
  const { state } = useLocation(); // data passed by the tiles in admin dashboard

  const [filters, setFilters] = useState({
    page: 1,
    user: null,
    membershipCategory: null,
    laborUnion: null,
    buildingConventionOutside77: null,
    workForce: null,
    isAdmin: false,
    isVerified: true,
    claimDate: state?.claimDate || null,
  });

  /**fetch labor union data**/
  useEffect(() => {
    fetchLaborUnionData();
    if (state) {
      setFilters((precFilters) => ({
        ...precFilters,
        claimDate: state?.claimDate || null,
        isVerified: state?.isVerified || null,
      }));
    }
  }, []);

  const fetchLaborUnionData = () => {
    axios
      .get(`${BACKEND_URL}/api/laborUnion/list`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        formatAndSetLaborUnion(response.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const formatAndSetLaborUnion = (list) => {
    const formattedOption = list.map((el) => ({
      text: el.label,
      value: el.id,
    }));
    setLaborUnionOption(formattedOption);
  };

  /**fetch user list when filters change**/
  useEffect(() => {
    fetchData();
  }, [filters]);

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`${BACKEND_URL}/api/user/list?`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: getQueryParams(),
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: "comma", encode: false });
        },
      })
      .then((response) => {
        setIsLoading(false);
        setTotalItems(response?.data?.totalItems);
        formatData(response.data[0]);
      })
      .catch((error) => {
        setIsLoading(false);
        openNotification("Erreur", error?.response?.data?.data, "error");
        console.error("error", error);
      });
  };

  // GET params filters + checks and removes unnecessary filters before sending the API request.
  const getQueryParams = () => {
    // console.log("filters", filters);
    Object.keys(filters).forEach((key) => {
      if (filters[key] === null) {
        delete filters[key];
      }
      // no need to send these if all options are selected
      const maxLength2 = [
        "buildingConventionOutside77",
        "workForce",
        "isUserAdmin",
        "isVerified",
      ];
      if (
        maxLength2.includes(key) &&
        typeof filters[key] !== "undefined" &&
        filters[key]?.length >= 2
      ) {
        delete filters[key];
      }
      if (
        (key === "membershipCategory" || key === "laborUnion") &&
        typeof filters[key] !== "undefined" &&
        filters[key]?.length >= 3
      ) {
        delete filters[key];
      }
    });
    return filters;
  };

  // Render form table of companies
  const formatData = (dataList) => {
    let formattedData = {};
    formattedData = dataList.map((el) => ({
      id: el.id,
      key: el.id,
      membershipCode: el.membershipCode,
      email: <div>{el.email}</div>,
      companyName: <div style={{ fontWeight: "bold" }}>{el.companyName}</div>,
      membershipCategory: (
        <div style={{ fontWeight: "bold" }}>{el.membershipCategory}</div>
      ),
      laborUnion: <div>{el.laborUnionLabel}</div>,
      workForce: (
        <div>{el.workForce === "upTo10" ? "Jusqu'à 10" : "Plus de 10"}</div>
      ),
      representativeName: (
        <div>{`${
          el?.representativeFirstname ? el?.representativeFirstname : ""
        } ${
          el?.representativeLastname ? el?.representativeLastname : ""
        }`}</div>
      ),
      buildingConventionOutside77: (
        <div>{el.buildingConventionOutside77 ? "oui" : "non"}</div>
      ),
      isAdmin: <div>{el.isAdmin ? "oui" : "non"}</div>,
      isVerified: <div>{el.isVerified ? "oui" : "non"}</div>,
      actions: el.isVerified ? (
        <div style={{ display: "flex", gap: "15px" }}>
          <Button
            onClick={() => handleEdit(el)}
            classNameElement="btn-edit-download"
            children={<EditOutlined />}
            title="Modifier"
          />
          <DetacheGroup
            page="userList"
            data={el}
            afterClose={handleResetFilters}
          />
          {el.hasBeneficiaryFile ? (
            <Button
              onClick={() => handleDownloadBeneficiaryFile(el.membershipCode)}
              classNameElement="btn-edit-download"
              children={<FileExcelOutlined />}
              title="Télécharger le fichier bénéficiaires"
            />
          ) : ''}
        </div>
      ) : (
        <div>
          {/* Company : nextEmail = true & isVerified = false*/}
          {el.nextEmail && (
            <Button
              onClick={() => handleForceValidateEmail(el)}
              classNameElement="btn-edit-download"
              title="Forcer validation email"
            >
              <CheckOutlined />
            </Button>
          )}
        </div>
      ),
    }));
    setData(formattedData);
  };

  /**Handle user information editing and email authentication**/
  // In case : email's user isVerified true => edit company
  const handleEdit = (record) => {
    setEditedRecord(record);
    setEditModalVisible(true);
  };

  const handleEditSave = (editedData) => {
    axios
      .post(`${BACKEND_URL}/api/admin/user/edit`, editedData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setEditModalVisible(false);
        handleResetFilters();
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  // In case : email's user isVerified false & nextEmail true => validate email
  const handleForceValidateEmail = (record) => {
    setEditedRecord(record);
    setForceValidateEmailModalVisible(true);
  };

  const handleForceValidateSave = () => {
    const payload = { membershipCode: editedRecord?.membershipCode };
    axios
      .post(`${BACKEND_URL}/api/admin/user/force-valid-user`, payload, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setForceValidateEmailModalVisible(false);
        fetchData();
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  /**Update filters**/
  // cas 1 : reset filters to default value
  const handleResetFilters = () => {
    setFilters({
      page: 1,
      user: null,
      membershipCategory: null,
      laborUnion: null,
      buildingConventionOutside77: null,
      workForce: null,
      isAdmin: false,
      isVerified: true,
    });
    setCategory([]);
    fetchLaborUnionData(); // to refresh laborUnions
    setWorkForce([]);
    setBuildingConventionOutside77([]);
    setUserIsAdmin(["false"]);
    setIsVerified(["true"]);
    setSearchValue("");
  };

  // cas 2: update filters with new data from table changes
  const handleUpdateFiltersFromTable = (e, value) => {
    // e => {current: 1, pageSize: 50, total: 24}
    // value => {membershipCategory: Array(0), buildingConventionOutside77: Array(0), workForce: null, isAdmin: Array(1), isVerified: Array(1), …
    setCategory(value.membershipCategory);
    setLaborUnion(value.laborUnion);
    setWorkForce(value.workForce);
    setBuildingConventionOutside77(value.buildingConventionOutside77);
    setUserIsAdmin(value.isAdmin);
    setIsVerified(value.isVerified);
    setFilters({
      page: e.current,
      membershipCategory: value.membershipCategory,
      laborUnion: value.laborUnion,
      buildingConventionOutside77: value.buildingConventionOutside77,
      workForce: value.workForce,
      isAdmin: value.isAdmin,
      isVerified: value.isVerified,
    });
  };

  // cas 3: update filters from search user
  const handleUpdateFiltersFromUserSearch = (e) => {
    // e => {label: 'Humbert', value: 'c_11', type: 'company', key: 0}
    setFilters((prevFilters) => ({
      page: filters.page,
      company: e?.type === "company" ? e.value : null,
      user: e?.type === "user" ? e.value : null,
      membershipCategory: prevFilters.membershipCategory,
      laborUnion: prevFilters.laborUnion,
      buildingConventionOutside77: prevFilters.buildingConventionOutside77,
      workForce: prevFilters.workForce,
      isAdmin: prevFilters.isAdmin,
      isVerified: prevFilters.isVerified,
    }));
  };

  /**Export CSV**/
  const handleExportCSV = () => {
    axios
      .get(`${BACKEND_URL}/api/csv/exportuser?`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json",
        },
        params: getQueryParams(),
        paramsSerializer: function(params) {
          return Qs.stringify(params, { arrayFormat: "comma", encode: false });
        },
        responseType: "blob",
      })
      .then((response) => {
        // Get filename from the Content-Disposition header
        const contentDisposition = response.headers['content-disposition'];
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        let filename = `export_adherents_${year}-${month}-${day}.xlsx`; // fallback
        
        // Extract filename from Content-Disposition header if available
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1];
          }
        }
        
        const href = URL.createObjectURL(response?.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const handleDownloadAllBeneficiaryFiles = (dateRange) => {
    setIsLoading(true);
    
    // URL de base
    let url = `${BACKEND_URL}/api/beneficiary-file/admin/download-all`;
    
    // Ajouter les paramètres de date si fournis
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      url += `?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
    }
    
    axios
      .get(url, {
        withCredentials: true,
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response?.data);
        const link = document.createElement("a");
        link.href = href;
        
        // Créer le nom du fichier
        const date = new Date();
        let fileName = 'beneficiaires_consolides';
        
        // Si un intervalle de dates est fourni, l'inclure dans le nom du fichier
        if (dateRange && dateRange.startDate && dateRange.endDate) {
          const startDate = new Date(dateRange.startDate * 1000);
          const endDate = new Date(dateRange.endDate * 1000);
          
          const formatDate = (date) => {
            return `${date.getDate().toString().padStart(2, '0')}_${(date.getMonth() + 1).toString().padStart(2, '0')}_${date.getFullYear()}`;
          };
          
          fileName += `_du_${formatDate(startDate)}_au_${formatDate(endDate)}`;
        } else {
          // Sinon, utiliser la date actuelle
          fileName += `_${date.getDate().toString().padStart(2, '0')}_${(date.getMonth() + 1).toString().padStart(2, '0')}_${date.getFullYear()}`;
        }
        
        fileName += '.xlsx';
        
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setIsLoading(false);
        
        openNotification(
          "Succès",
          "Téléchargement des fichiers bénéficiaires consolidés réussi",
          "success"
        );
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.status === 404) {
          openNotification(
            "Information",
            "Aucun fichier bénéficiaire n'est disponible",
            "info"
          );
        } else {
          openNotification(
            "Erreur",
            "Impossible de télécharger les fichiers bénéficiaires",
            "error"
          );
        }
        console.error("error", error);
      });
  };

  const handleDownloadBeneficiaryFile = (membershipCode) => {
    axios
      .get(`${BACKEND_URL}/api/beneficiary-file/download/${membershipCode}`, {
        withCredentials: true,
        responseType: "blob",
      })
      .then((response) => {
        // Le fichier existe, on le télécharge
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        
        // Extraire le nom du fichier depuis les headers si disponible
        const contentDisposition = response.headers["content-disposition"];
        let filename = `ADH${membershipCode}-beneficiaires.xlsx`;
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename=([^;]*)/);
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1];
          }
        }
        
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        openNotification(
          "Succès",
          "Téléchargement du fichier bénéficiaire réussi",
          "success"
        );
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          openNotification(
            "Information",
            "Aucun fichier bénéficiaire n'est disponible pour cette entreprise",
            "info"
          );
        } else {
          openNotification(
            "Erreur",
            "Impossible de télécharger le fichier bénéficiaire",
            "error"
          );
        }
        console.error("error", error);
      });
  };

  const exportButton = (
    <Button 
    onClick={handleExportCSV} 
    children={"Export Excel"} 
  />
  );

  return (
    <div className="block-right">
      <div className="info-table">
        <div className="input-container">
          <div className="findUser-container">
            <SearchAutocomplete
              entity="user"
              name="user"
              defaultValue={null}
              style={{ width: "200px", marginRight: "20px" }}
              onValidate={handleUpdateFiltersFromUserSearch}
              handleChange={(e) => setSearchValue(e)}
              value={searchValue}
            />

            <Button
              onClick={handleResetFilters}
              children={<ClearOutlined />}
              title="Reset"
            />
          </div>

          <div className="findMond-reset-container">
            <div></div>
          </div>
          <div style={{ display: "flex", gap: "30px" }}>
            <DownloadDropdown onDownload={handleDownloadAllBeneficiaryFiles} exportComponent={exportButton} />
          </div>
        </div>
        <div>
          <TableDeclarationsUserList
            category={category}
            laborUnionOption={laborUnionOption}
            laborUnion={laborUnion}
            buildingConventionOutside77={buildingConventionOutside77}
            workForce={workForce}
            userIsAdmin={userIsAdmin}
            isVerified={isVerified}
            data={data}
            filters={filters}
            totalItems={totalItems}
            handleTableChange={handleUpdateFiltersFromTable}
            isLoading={isLoading}
            handleDownloadBeneficiaryFile={handleDownloadBeneficiaryFile}
          />
        </div>
      </div>
      <EditUserModal
        open={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        onEdit={handleEditSave}
        record={editedRecord}
        laborUnionOption={laborUnionOption}
      />
      <ForceValidateEmailModal
        open={forceValidateEmailModalVisible}
        onCancel={() => setForceValidateEmailModalVisible(false)}
        onValidate={handleForceValidateSave}
        record={editedRecord}
      />
    </div>
  );
}