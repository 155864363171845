import axios from "axios";
import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../../config/config";
import { Select, Space, Checkbox, Spin, Alert, Modal, Form, Input } from "antd";
import { handleMoneyChange } from '../../../utils/moneyInputUtils';
import { NotificationContext } from "../../context/NotificationWrapper";
import { useDebounce } from "../../../hooks/useDebounce";
import { DeclarationContext } from "../../context/DeclarationWrapper";
import { AuthContext } from "../../context/AuthProviderWrapper";
import { renderCheckboxOrHidden } from "../../helper-function/helperFunction";
import {
  FooterEditDeclarationModal,
  TitleEditDeclarationModal,
} from "./EditDeclaration/chooseTiitleAndHeader";

const monthsData = [
  { value: "1", label: "Janvier" },
  { value: "2", label: "Février" },
  { value: "3", label: "Mars" },
  { value: "4", label: "Avril" },
  { value: "5", label: "Mai" },
  { value: "6", label: "Juin" },
  { value: "7", label: "Juillet" },
  { value: "8", label: "Août" },
  { value: "9", label: "Septembre" },
  { value: "10", label: "Octobre" },
  { value: "11", label: "Novembre" },
  { value: "12", label: "Décembre" },
];

const EditDeclarationModal = ({ open, onCancel, record, mode }) => {
  const navigate = useNavigate();
  const { user, isAdmin } = useContext(AuthContext);
  const { openNotification } = useContext(NotificationContext);
  const { updateDeclaration } = useContext(DeclarationContext);
  const backendUrl = BACKEND_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [isInputLoading, setIsInputLoading] = useState(false);
  const [error, setError] = useState({});
  const [conditionCheckbox, setConditionCheckbox] = useState(false);
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);


  const [salary, setSalary] = useState({
    salaryWorker: record?.salaryWorker,
    salaryEtam: record?.salaryEtam,
    salaryExecutive: record?.salaryExecutive,
    workForce: record?.workForce,
    month: record?.month,
    year: record?.year,
    agreeOptionalWorker: record?.agreeOptionalWorker,
  });

  const [taxes, setTaxes] = useState({
    feeExecutive: record ? record.feeExecutive : 0,
    feeEtam: record ? record.feeEtam : 0,
    feeWorker: record ? record.feeWorker : 0,
    tvaExecutive: record ? record.tvaExecutive : 0,
    tvaEtam: record ? record.tvaEtam : 0,
    tvaWorker: record ? record.tvaWorker : 0,
    feeAmount: record ? record.feeAmount : 0,
  });

  useEffect(() => {
    if (record) {
      setSalary({
        salaryWorker: record.salaryWorker,
        salaryEtam: record.salaryEtam,
        salaryExecutive: record.salaryExecutive,
        workForce: record.workForce,
        month: record.month,
        year: record.year,
        agreeOptionalWorker: record.agreeOptionalWorker,
      });
    }
  }, [record]);

  //   const [nextMembershipCategory, setNextMembershipCategory] = useState({
  //   category: record ? record.membershipCategory : null,
  // });
  const abortControllerRef = useRef(null);
  const debouncedSalaryExecutive = useDebounce(salary.salaryExecutive);
  const debouncedSalaryEtam = useDebounce(salary.salaryEtam);
  const debouncedSalaryWorker = useDebounce(salary.salaryWorker);
  const debouncedWorkForce = useDebounce(salary.workForce);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        ...record,
        salaryExecutiveFee: record?.feeExecutiveLocale,
        salaryExecutiveTva: record?.tvaExecutiveLocale,
        salaryEtamFee: record?.feeEtamLocale,
        salaryEtamTva: record?.tvaEtamLocale,
        salaryWorkerFee: record?.feeWorkerLocale,
        salaryWorkerTva: record?.tvaWorkerLocale,
        identifier: record?.feeAmountLocale,
      });
    }
  }, []);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        ...record,
        identifier: record?.feeAmountLocale,
        salaryExecutiveFee: record?.feeExecutiveLocale,
        salaryExecutiveTva: record?.tvaExecutiveLocale,
        salaryEtamFee: record?.feeEtamLocale,
        salaryEtamTva: record?.tvaEtamLocale,
        salaryWorkerFee: record?.feeWorkerLocale,
        salaryWorkerTva: record?.tvaWorkerLocale,
      });
    }
  }, [record, form]);

  useEffect(() => {
    if (
      typeof debouncedSalaryExecutive === "undefined" &&
      typeof debouncedSalaryEtam === "undefined" &&
      typeof debouncedSalaryWorker === "undefined" &&
      typeof debouncedWorkForce === "undefined"
    ) {
      return;
    }
    if (mode === "edit") {
      onSalaryChange(
        salary,
        record,
        setIsInputLoading,
        setTaxes,
        form,
        mode,
        openNotification,
        record?.membershipCode
      );
    }
  }, [
    debouncedSalaryExecutive,
    debouncedSalaryEtam,
    debouncedSalaryWorker,
    debouncedWorkForce,
  ]);

  useEffect(() => {
    const condition = renderCheckboxOrHidden(user, salary);
    setConditionCheckbox(condition);
  }, [user, salary]);

  const onSalaryChange = (
    salary,
    record,
    setIsInputLoading,
    setTaxes,
    form,
    mode,
    openNotification,
    membershipCode
  ) => {
    const payload = {
      salaryWorker: handleMoneyChange(salary?.salaryWorker || ""),
      salaryEtam: handleMoneyChange(salary?.salaryEtam || ""),
      salaryExecutive: handleMoneyChange(salary?.salaryExecutive || ""),
      workForce: parseInt(salary?.workForce, 10) || 0,
      membershipCode: membershipCode,
    };
    
    setIsInputLoading(true);
    axios
      .post(`${BACKEND_URL}/api/declaration/getTaxesAutocomplete`, payload, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        const {
          feeExecutive,
          feeEtam,
          feeWorker,
          tvaExecutive,
          tvaEtam,
          tvaWorker,
          feeAmount,
          membershipCategory,
        } = response.data;
        
        setTaxes({
          feeExecutive: feeExecutive,
          feeEtam: feeEtam,
          feeWorker: feeWorker,
          tvaExecutive: tvaExecutive,
          tvaEtam: tvaEtam,
          tvaWorker: tvaWorker,
          feeAmount: feeAmount,
        });
        
        if (mode === "edit") {
          form.setFieldsValue({
            salaryExecutiveFee: feeExecutive,
            salaryEtamFee: feeEtam,
            salaryWorkerFee: feeWorker,
            tvaExecutive: tvaExecutive,
            tvaEtam: tvaEtam,
            tvaWorker: tvaWorker,
            feeAmount: feeAmount,
          });
        }
      })
      .catch((error) => {
        console.error("error", error);
        if (openNotification) {
          openNotification("Erreur", error.response?.data?.data || "Une erreur est survenue", "error");
        }
      })
      .finally(() => {
        setIsInputLoading(false);
      });
  };

  const onCloseModal = () => {
    setError({});
    onCancel();
    form.resetFields();
    if (form && record) {
      form.setFieldsValue({
        salaryExecutiveFee: record?.feeExecutiveLocale,
        salaryExecutiveTva: record?.tvaExecutiveLocale,
        salaryEtamFee: record?.feeEtamLocale,
        salaryEtamTva: record?.tvaEtamLocale,
        salaryWorkerFee: record?.feeWorkerLocale,
        salaryWorkerTva: record?.tvaWorkerLocale,
      });
    }
  };

  const handleChange = (changedValues, allValues) => {
    const [key, value] = Object.entries(changedValues)[0];
    const formattedValueMoneyChange = handleMoneyChange(value);

    let updatedSalary = {
      ...salary,
      [key]:
        key === "salaryExecutive" ||
        key === "salaryEtamFee" ||
        key === "salaryWorkerFee"
          ? formattedValueMoneyChange
          : value,
    };

    if (key === "workForce" && value < 10) {
      updatedSalary = {
        ...updatedSalary,
        agreeOptionalWorker: false,
      };
    }

    setSalary(updatedSalary);
  };

  const handleChangeMonth = (e) => {
    setSalary({ ...salary, month: e });
  };

  const handleChangeYear = (selectedYear) => {
    const currentYear = new Date().getFullYear().toString();
    const currentMonth = new Date().getMonth() + 1;
  
    setSalary(prev => ({
      ...prev,
      year: selectedYear,
      month: selectedYear === currentYear ? currentMonth.toString() : prev.month
    }));
    form.setFieldsValue({
      ...form,
      year: selectedYear
    })
  };

  const onFinish = () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setIsLoading(true);
    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();
    setError({});

    const requestBody = {
      salaryWorker: salary?.salaryWorker,
      salaryEtam: salary?.salaryEtam,
      salaryExecutive: salary?.salaryExecutive,
      workForce: parseInt(salary.workForce, 10),
      month: salary?.month,
      year: salary?.year,
      agreeOptionalWorker: salary?.agreeOptionalWorker,
      membershipCode: record?.membershipCode,
      id: record?.id
    };

    if (!record?.id) {
      axios
        .post(`${backendUrl}/api/declaration/check-validity`, requestBody, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          signal: abortControllerRef.current?.signal,
        })
        .then((res) => {
          openNotification("Succes", "Déclaration modifiée", "success");
          onCloseModal();
          return res.data.data;
        })
        .then((res) => {
          updateDeclaration(res);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            return;
          }
          if (error.response && error.response.data) {
            if (error.response.data.description) {
              setError([error.response.data.description]);
            } else if (Array.isArray(error.response.data.data)) {
              setError(error.response.data.data);
            } else if (error.response.data.data) {
              setError([error.response.data.data]);
            } else {
              setError([error.response.data.message || "Une erreur est survenue"]);
            }
          } else {
            setError(["Une erreur inattendue s'est produite"]);
          }
          console.error("error", error);
        })
        .finally(() => {
          setIsLoading(false);
          setIsSubmitting(false);
        });
    } else {
        if (
          window.confirm(
            "Êtes vous sur de vouloir modifier cette déclaration ?"
          ) === true
        ) {
          axiosEditDeclaration(requestBody);
        } else {
          setIsLoading(false);
          setIsSubmitting(false);
          return;
        }
    }
  };

  const axiosEditDeclaration = (requestBody) => {
    axios
      .post(`${backendUrl}/api/declaration/submit`, requestBody, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        signal: abortControllerRef.current?.signal,
      })
      .then(() => {
        openNotification("Succes", "Déclaration modifiée", "success");
        setError({});
        onCloseModal();
        navigate("/tableDeclaration");
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          return;
        }
        if (error.response && error.response.data) {
          if (error.response.data.description) {
            setError([error.response.data.description]);
          } else if (Array.isArray(error.response.data.data)) {
            setError(error.response.data.data);
          } else if (error.response.data.data) {
            setError([error.response.data.data]);
          } else {
            setError([error.response.data.message || "Une erreur est survenue"]);
          }
        } else {
          setError(["Une erreur inattendue s'est produite"]);
        }
        openNotification(
          "Erreur",
          "Les données soumises sont incorrectes",
          "error"
        );
        console.error("error", error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsSubmitting(false);
      });
  };

  return (
    <Modal
      title={<TitleEditDeclarationModal record={record} />}
      open={open}
      onCancel={onCloseModal}
      footer={null}
      destroyOnClose={true}
      width={800}
    >
      {error?.length > 0 && (
        <Alert
          type="error"
          message={error.map((el, index) => <p key={index}>{el}</p>)}
        />
      )}
      <Form
        form={form}
        name="registerUser"
        onValuesChange={handleChange}
        onFinish={onFinish}
        scrollToFirstError
        initialValues={record}
      >
        <table>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Salaire</th>
              <th scope="col">Cotisation (0,4%)</th>
              <th scope="col">TVA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Salaire Brut Cadres</th>
              <td>
                <Form.Item
                  name="salaryExecutive"
                  rules={[
                    {
                      required: true,
                      message: "Veillez entrer le Salaire Brut Cadres",
                    },
                  ]}
                  // hasFeedback
                >
                  <Input
                    type="text"
                    inputMode="numeric"
                    disabled={mode === "view"}
                    onChange={(event) => {
                      // Pour antd Input, récupérer directement la valeur
                      const value = event.target.value;
                      // Formater la valeur
                      const formattedValue = handleMoneyChange(value);
                      // Mettre à jour le champ
                      form.setFieldsValue({ salaryExecutive: formattedValue });
                    }}
                  />
                </Form.Item>
              </td>

              <td>
                <div className="input-container">
                  {isInputLoading && (
                    <div className="spin-overlay">
                      <Spin />
                    </div>
                  )}
                  <Form.Item name="salaryExecutiveFee">
                    <Input readOnly />
                  </Form.Item>
                </div>
              </td>
              <td>
                <div className="input-container">
                  {isInputLoading && (
                    <div className="spin-overlay">
                      <Spin />
                    </div>
                  )}

                  <Form.Item name="salaryExecutiveTva">
                    <Input readOnly />
                  </Form.Item>
                </div>
              </td>
            </tr>

            <tr>
              <th>Salaire Brut ETAM</th>
              <td>
                <Form.Item
                  name="salaryEtam"
                  rules={[
                    {
                      required: true,
                      message: "Veillez entrer le Salaire Brut ETAM",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    inputMode="numeric"
                    step={0.01}
                    min={0}
                    disabled={mode === "view"}
                    onChange={(event) => {
                      // Pour antd Input, récupérer directement la valeur
                      const value = event.target.value;
                      // Formater la valeur
                      const formattedValue = handleMoneyChange(value);
                      // Mettre à jour le champ
                      form.setFieldsValue({ salaryEtam: formattedValue });
                    }}
                    // style={mode === "view" ? inputViewMode : null}
                  />
                </Form.Item>
              </td>
              <td>
                <div className="input-container">
                  {isInputLoading && (
                    <div className="spin-overlay">
                      <Spin />
                    </div>
                  )}

                  <Form.Item name="salaryEtamFee">
                    <Input readOnly />
                  </Form.Item>
                </div>
              </td>
              <td>
                <div className="input-container">
                  {isInputLoading && (
                    <div className="spin-overlay">
                      <Spin />
                    </div>
                  )}

                  <Form.Item name="salaryEtamTva">
                    <Input readOnly />
                  </Form.Item>
                </div>
              </td>
            </tr>

            <tr>
              <th>
                Salaire Brut Ouvriers{" "}
                {salary?.workForce > 10 ? <div>(opt.)</div> : ""}
              </th>
              <td>
                <Form.Item name="salaryWorker">
                  <Input
                    step={0.01}
                    min={0}
                    inputMode="numeric"
                    disabled={mode === "view"}
                    onChange={(event) => {
                      // Pour antd Input, récupérer directement la valeur
                      const value = event.target.value;
                      // Formater la valeur
                      const formattedValue = handleMoneyChange(value);
                      // Mettre à jour le champ
                      form.setFieldsValue({ salaryWorker: formattedValue });
                    }}
                    // style={mode === "view" ? inputViewMode : null}
                  />
                </Form.Item>
              </td>
              <td>
                <div className="input-container">
                  {isInputLoading && (
                    <div className="spin-overlay">
                      <Spin />
                    </div>
                  )}

                  <Form.Item name="salaryWorkerFee">
                    <Input readOnly />
                  </Form.Item>
                </div>
              </td>
              <td>
                <div className="input-container">
                  {isInputLoading && (
                    <div className="spin-overlay">
                      <Spin />
                    </div>
                  )}

                  <Form.Item name="salaryWorkerTva">
                    <Input readOnly />
                  </Form.Item>
                </div>
              </td>
            </tr>

            <tr>
              <th>Date</th>
              <td>
                <Form.Item name="month">
                  <Space wrap>
                    <Select
                      className="select"
                      disabled={mode === "view"}
                      value={monthsData[salary.month -1]}
                      onChange={handleChangeMonth}
                      options={monthsData.map(month => ({
                        ...month,
                        disabled: form.getFieldValue('year') === new Date().getFullYear().toString() && 
                                 parseInt(month.value) > new Date().getMonth() + 1
                      }))}
                    />
                  </Space>
                </Form.Item>
              </td>
              <td>
                <Form.Item name="year">
                  <Space wrap>
                    <Select
                      className="select"
                      disabled={mode === "view"}
                      defaultValue={record?.year}
                      onChange={handleChangeYear}
                      options={[
                        { value: "2025", label: "2025" },
                        { value: "2024", label: "2024" },
                        { value: "2023", label: "2023" },
                      ]}
                    />
                  </Space>
                </Form.Item>
              </td>
              <td></td>
            </tr>

            <tr>
              <th>Effectifs</th>
              <td>
                <Form.Item
                  name="workForce"
                  rules={[
                    {
                      required: true,
                      message: "Veillez entrer le nombre des effectifs",
                    },
                  ]}
                >
                  <Input min={1} step={1} type="number" disabled={mode === "view"}/>
                </Form.Item>
              </td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <th>Montant dû</th>
              <td>
                <div className="input-container">
                  {isInputLoading && (
                    <div className="spin-overlay">
                      <Spin />
                    </div>
                  )}

                  <Form.Item name="identifier">
                    <Input defaultValue={record?.feeAmountLocale} readOnly />
                  </Form.Item>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>

        {/* Condition: record?.laborUnion !== 2 && salary?.workForce > 10) || (record?.laborUnion !== 2 && !salary && record.workForce > 10 */}
        {conditionCheckbox && (
          <Form.Item name="agreeOptionalWorker" valuePropName="checked">
            <Checkbox
              checked={
                salary?.agreeOptionalWorker ||
                (!salary && record.agreeOptionalWorker)
              }
              disabled={mode === "view"}
              required
            >
              J'ai pris connaissance que la souscription aux Prestations pour
              les Ouvriers est optionnelle si les effectifs sont supérieurs à
              10.
            </Checkbox>
          </Form.Item>
        )}

        <div>
          <FooterEditDeclarationModal
            mode={mode}
            isLoading={isLoading}
            onCloseModal={onCloseModal}
            onSave={() => onFinish()}
            record={record}
            isAdmin={isAdmin}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default EditDeclarationModal;
